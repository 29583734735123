
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import moment from "moment";
import { handleErrorUI } from "@/util/error";
import SelectReportDialog from "../components/PeriodicReport/SelectReportDialog";
import { useStoreOption } from "@/components/PeriodicReport/SelectStore/useStore";
import PaginationMixin from "@/mixins/PaginationMixin";
import { APICreatePreferencesPulse, APIGetFileURL } from "@/api/kpi";
import {exportToPDF} from '@/util/reports/exportopdf.js'

export default {
  components: { SelectReportDialog },
  mixins: [PaginationMixin],
  data() {
    return {
      rowTemp: {},
      showPreviewModal: false,
      selected: [],
      searchKeyword: "",
      confirmDelete: false,
      currentRow: {},
      loading: false,
      //dataType: "all",
      dataType: "ALL",
      startDate: "",
      endDate: "",
      showDialog: false,
      currentSelectedStore: "all",
      storeObj: {},
      tableLoading: false,
      dailyChk: false,
      weeklyChk: false,
      monthlyChk: true,
      isReportView: false,
      
      fileStartingPeriod : '',
      fileEndingPeriod : '',
      fileStoreCode : '',
      reportPath: {
        DAILY: "/daily-report",
        WEEKLY: "/weekly-report",
        MONTHLY: "/monthly-report",
      },
      columns: [
        {
          name: "date",
          required: true,
          label: this.$t("Library.Date"),
          align: "left",
          field: (row) =>
            `${row.startDate} ${row.reportType === "DAILY" ? "" : "~" + row.endDate}`,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "storeCodes",
          align: "center",
          label: this.$t("Library.Store"),
          field: "storeCodes",
          sortable: true,
        },
        {
          name: "storeName",
          align: "center",
          label: this.$t("SettingsManagement.storeName"),
          field: "storeName",
          sortable: true,
        },
        {
          name: "cDate",
          align: "center",
          label: this.$t("Library.Creation"),
          field: "cDate",
          sortable: true,
          format: (val) => this.formatsDate(val),
        },
        {
          name: "reportType",
          align: "center",
          label: this.$t("Library.ReportType"),
          field: "reportType",
          format: (val) => this.$t(`Date.${val?val.toLowerCase():''}`),
          sortable: true,
        },
        {
          name: "actions",
          align: "center",
          label: this.$t("Library.Actions"),
          field: "actions",
          sortable: true,
        },
      ],
      tableData: [],
    };
  },
  computed: {
    ...mapState('store', ['stores']),
    ...mapState(["storeList"]),
    ...mapState("periodicReport", ["reportList", "selectedStoreCodePulse"]),
    ...mapState("filter", ["startingPeriod", "endingPeriod"]),
    ...mapGetters("filter", ["getStartingPeriod", "getEndingPeriod"]),
    ...mapState("marketplace", ["filterLibrary"]),
  },
  watch: {
   
    getStartingPeriod(newValue) {
      this.startDate = newValue;
    },
  /*  getEndingPeriod(newValue) {
      this.endDate = newValue;
    },*/

    dataType(newValue) {
      this.loadData();
    },
    currentSelectedStore(newValue) {
      this.loadData();
    },
    selectedStoreCodePulse(newValue) {
      this.currentSelectedStore = newValue;
    },
  },
  async created() {
    const dateFrom = moment().subtract(7, "d").format("YYYY-MM-DD");
    this.startDate = this.filterLibrary.startingPeriod;
    this.endDate = this.filterLibrary.endingPeriod;
    await this.getStoreList();
    
  },
  methods: {
    ...mapActions("user",['fetchStoreList']),
    formatsDate(val) {
      return moment(val.date).format("YYYY-MM-DD");
    },
     toggleModal(value) {
      this.showPreviewModal = value;
    },
    changeDates(val) {
      this.startDate = val.startDate;
      this.endDate = val.endDate;
      this.loadData();
    },
    async loadData() {
       if(this.dataType == 'ALL')
       {
        this.tableLoading = true;
        await this.getAllPeriodicReport({
          startDate: moment(this.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.endDate).format("YYYY-MM-DD"),
          storeCodes: this.currentSelectedStore || "all",
        });
       }
       else
       {
        this.tableLoading = true;
        await this.getAllPeriodicReport({
          reportType: this.dataType,

          startDate: moment(this.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.endDate).format("YYYY-MM-DD"),
          storeCodes: this.currentSelectedStore || "all",
        });
       }
        var tableData = this.reportList.map((singleReport) => {
          if (!this.storeObj[singleReport.storeCodes]) this.tableLoading = true;
          else this.tableLoading = false;
          return {
            ...singleReport,
            storeName: this.storeObj[singleReport.storeCodes],
          };
        });
        var sort = tableData.sort((a, b) => {
          return new Date(b.cDate.date) - new Date(a.cDate.date);//here could be updated date
        });
        this.tableData = sort;
        this.tableLoading = false;
    },
    getSelectedString() {
      return this.selected.length === 0
        ? ""
        : `${this.selected.length} record${
            this.selected.length > 1 ? "s" : ""
          } selected of ${this.tableData.length}`;
    },
    async getStoreList() {
      var storeOptionList = [];
      if (!this.storeList) {
        storeOptionList = await this.fetchStoreList();
      } else storeOptionList = this.storeList;
      //const storeOptionList = await useStoreOption();
      let obj = {};
      storeOptionList.map((storeOption) => {
        const splittedArray = storeOption.label.split("-");
        obj[splittedArray[0].trim()] = splittedArray[1].trim();
      });
      this.storeObj = obj;
    },
    async editRow(row) {
      this.setStore(row.storeCodes);
      this.setCurrentReport({ currentReport: row });
      this.setPersistentKPI(row.kpis.split(","));
      this.setEndingPeriod({ endingPeriod: row.endDate });
      this.setStartingPeriod({ startingPeriod: row.startDate });
      this.setStartingCompared({ startingCompared: row.compareStartDate });
      this.setEndingCompared({ endingCompared: row.compareEndDate });
      const reportPath = this.reportPath[row.reportType];

      this.$router.push({ path: `${reportPath}/${row._id}` });
    },
    async deleteRow() {
      try {
      try{
        await this.deleteReport(this.currentRow._id);
        await this.loadData();
        this.$q.notify({
            message: this.$t("SettingsManagement.successfully_deleted"),
            type: "positive",
            position: "top",
            timeout: 3000,
          });
        } catch (ex) {
          this.$q.notify({
            message: this.$t("SettingsManagement.deletionFailed"),
            type: "negative",
            position: "top",
            timeout: 3000,
          });
      }
      } catch (err) {
        handleErrorUI(err);
      }
    },
    downloadRow1(row) {
      this.isReportView =true;
      if(row.reportType == "WEEKLY")
      {
        this.weeklyChk = true;
        this.dailyChk = false;
        this.monthlyChk = false;
      }
      else if(row.reportType == 'DAILY') {
        this.weeklyChk = false;
        this.dailyChk = true;
        this.monthlyChk = false;
      }
      else {
        this.weeklyChk = false;
        this.dailyChk = false;
        this.monthlyChk = true;
      }
      console.log('row')
      console.log(row)
      
      this.rowTemp = row;
      this.setHoulyChartDate({
        selectedPeriod: {
          start: moment(row.startDate),
          end: moment(row.endDate),
        },
        comparedPeriod: {
          start: moment(row.compareStartDate),
          end: moment(row.compareEndDate),
        },
      });
      this.fileStartingPeriod = row.startDate;
      this.fileEndingPeriod = row.endDate;
      this.fileStoreCode = row.storeCodes;
      var listOfMarkedCities = this.$store.state.user.storesCodeNameList?.filter(v => v.storeCode === row.storeCodes) || [];

      this.setStore(row.storeCodes);
      this.setSelectedStore({ label: row.storeCodes + " - " + row.storeName , cityId: listOfMarkedCities?listOfMarkedCities[0].cityId:'1'});
      this.setPersistentKPI(row.kpis.split(","));
      this.setPulseStartingPeriod(moment(row.startDate).format("YYYY-MM-DD"));
      this.setPulseEndingPeriod(moment(row.endDate).format("YYYY-MM-DD"));
      this.setPulseStartingCompared(moment(row.compareStartDate).format("YYYY-MM-DD"));
      this.setPulseEndingCompared(moment(row.compareEndDate).format("YYYY-MM-DD"));
      this.setFilterForm({
        filterFormKey: "numberOfEmployees",
        filterFormValue: row.numberOfEmployees?row.numberOfEmployees:null,
      });
      this.setFilterForm({
        filterFormKey: "attendanceStaff",
        filterFormValue: row.attendanceStaff?row.attendanceStaff:null,
      });
      this.setFilterForm({
        filterFormKey: "reportTitle",
        filterFormValue: row.reportTitle,
      });
      this.setFilterForm({
        filterFormKey: "marketReport",
        filterFormValue: row.marketReport,
      });
      this.setFilterForm({
        filterFormKey: "nextActionPlan",
        filterFormValue: row.nextActionPlan,
      });
      this.setFilterForm({
        filterFormKey: "lastWeekActionPlan",
        filterFormValue: row.lastWeekActionPlan,
      });
      this.setFilterForm({
        filterFormKey: "reviewActionPlan",
        filterFormValue: row.reviewActionPlan,
      });
      this.isReportView =false;
      this.showPreviewModal = true;
    
    },
    async downloadRow(row) {
      
      this.loading = true;
      const pdfFile = await exportToPDF(".report-preview", this.$q.notify);
      var fileName = "PeriodicReport.pdf";

      

      if(this.weeklyChk)
        fileName = 'PeriodicReport-Weekly-'+this.fileStartingPeriod+'_'+this.fileEndingPeriod+'_'+this.fileStoreCode+'.pdf';
      else if(this.dailyChk)
        fileName = 'PeriodicReport-Daily-'+this.fileStartingPeriod+'_'+this.fileEndingPeriod+'_'+this.fileStoreCode+'.pdf';
      else
        fileName = 'PeriodicReport-Monthly-'+this.fileStartingPeriod+'_'+this.fileEndingPeriod+'_'+this.fileStoreCode+'.pdf';
      
      pdfFile.name = fileName;
        // Download PDF file
      const blob = new Blob([pdfFile], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.currentSelectedStore = 'all'
       this.showPreviewModal = false;
       
      this.loading = false;
      
    },
    ...mapMutations("periodicReport", [
      "setCurrentReport",
      "setStore",
      "setSelectedStore",
      "setFilterForm",
      "setHoulyChartDate"
    ]),
    ...mapMutations("distribution", ["setPersistentKPI"]),
    ...mapMutations("filter", [
      "setPulseStartingPeriod",
      "setPulseEndingPeriod",
      "setPulseStartingCompared",
      "setPulseEndingCompared",
      "setCustomCalenderFlag",
      "setStartingPeriod",
      "setEndingPeriod",
      "setStartingCompared",
      "setEndingCompared",
    ]),
    ...mapActions("periodicReport", [
      "getAllPeriodicReport",
      "deleteReport",
      "downloadPeriodicReport",
    ]),
  },
};
